<template>
	<div class="pa-4">
		<template v-if="!status.loggedIn">
		<h2 class="mb-6">用户登陆</h2>
			<form>
			<v-text-field
				ref="name"
				v-model="email"
				:rules="[rules.required]"
				:error-messages="errorMessages"
				label="登陆邮箱"
				placeholder="请输入您的登陆邮箱"
				required
				class="mb-4"
			></v-text-field>
			<v-text-field
				v-model="password"
				:append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
				:rules="[rules.required, rules.min]"
				:type="showpassword ? 'text' : 'password'"
				name="input-10-1"
				label="请输入您的密码"
				hint="密码至少包含8个字符"
				@click:append="showpassword = !showpassword"
				counter
			></v-text-field>
			<div class="d-flex justify-end">
			<v-btn
				color="primary"
				depressed
				elevation="0"
				class="mt-4"
				@click="userlogin()"
				>
					<v-icon left>mdi-account-arrow-right</v-icon>
				登陆系统</v-btn>
			</div>
		</form>
		</template>
		<template v-else>
			<v-alert
				outlined
				type="success"
				text
			>您已经登陆系统了。
			</v-alert>
		</template>
	</div>
</template>

<script>

import { mapState, mapActions } from 'vuex';

export default {
	name: 'Login',
	data: () => ({
		email: null,
		password: '',
		showpassword: false,
		errorMessages: '',
		rules: {
			required: value => !!value || '这是必填内容.',
			min: v => v.length >= 8 || '密码至少8个字符长度',
			emailMatch: () => (`您输入的登陆邮箱或密码错误`),
		},
		formHasErrors: false,
	}),
	watch: {
		email () {
			this.errorMessages = ''
		},
	},
	computed: {
		form () {
			return {
				email: this.email,
			}
		},
		...mapState('user', ['status', 'message', 'user'],)
	},
	methods: {
		...mapActions('user', ['login', 'logout']),
		userlogin(){
			const { email, password } = this;
			this.login({ email, password });
			this.errorMessages = this.message;
		}
	}
}
</script>
