import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../page/Home.vue'


Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/account',
		name: 'account',
		component: () => import('../page/account/account_list.vue')
	},
	{
		path: '/account/:id',
		name: 'viewAccount',
		component: () => import('../page/account/account_view.vue')
	},
	{
		path: '/account/?role=:role',
		name: 'createAccount',
		component: () => import('../page/account/account_view.vue')
	},
	{
		path: '/timesheet',
		name: 'timesheet',
		component: () => import('../page/timesheet/timesheet_list.vue')
	},
	{
		path: '/timesheet/project/:id',
		name: 'timesheetProject',
		component: () => import('../page/timesheet/timesheet_project.vue'),
	},
	{
		path: '/timesheet/project/team/:id',
		name: 'timesheetTeam',
		component: () => import('../page/timesheet/timesheet_team.vue'),
	},
	{
		path: '/project',
		name: 'project',
		component: () => import('../page/project/project_list.vue')
	},
	{
		path: '/view-project/:id',
		name: 'viewProject',
		component: () => import('../page/project/project_view.vue')
	},
	{
		path: '/team',
		name: 'team',
		component: () => import('../page/team/team_list.vue')
	},
	{
		path: '/team/:id',
		name: 'viewTeam',
		component: () => import('../page/team/team_view.vue')
	},
	{
		path: '/worker/:id',
		name: 'viewWorker',
		component: () => import('../page/worker/worker_view.vue')
	},
	{
		path: '/site',
		name: 'site',
		component: () => import('../page/site/site_list.vue')
	},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
