import Api from '@/services/Api';

export default {
	userLogin(email, password) {
		const body = {
			"email": email,
			"password": password
		}
		return Api().post(`/authentication`, JSON.stringify(body));
	},
	userLogout(){
		return Api().post(`/authentication/revoke`);
	},
	getCurrenUserInfo(){
		return Api().get(`/userInfo`);
	},
	getUserList(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/user?${queryString}`);
	},
	createUser(body){
		return Api().post(`/user`, JSON.stringify(body));
	},
	updateUser(user_id, body){
		return Api().put(`/user/${user_id}`, JSON.stringify(body));
	},
	getUserById(user_id){
		return Api().get(`/user/${user_id}`);
	},
	getUserWageRates(user_id){
		return Api().get(`/user/${user_id}/wage_rate`)
	},
	createUserWageRate(user_id, body){
		return Api().post(`/user/${user_id}/wage_rate`, JSON.stringify(body));
	},
	verifyPassword(password){
		const body = {
			"password": password
		}
		return Api().post(`userInfo/password/verification`, JSON.stringify(body));
	}
}