
import axios from 'axios';
import router from '../router';

export default() => {

	const apiUrl = process.env.VUE_APP_API_HOST;
	const userSession = JSON.parse(localStorage.getItem('user_session'));

	const customAxios = axios.create({
		baseURL: apiUrl,
		withCredentials: false,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,POST',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	})

	const requestHandler = request => {
		if(userSession){
			const token = userSession.token;
			request.headers.Authorization = `Bearer ${token}`;
		}
		return request;
	};

	const responseHandler = response => {	
		 //console.log('interceptors-response: ' + response);
		// console.log(response);
		return response;
	};

	const errorHandler = error => {
		console.log('interceptors-error: ' + error);
		//console.log(error.response.status )
		if (error.response.status === 401) {
			localStorage.removeItem('user_session');
			router.push('/');
			window.location.reload();
			//return to login page
			//got to login page
		}

		return Promise.reject(error.response);
	};

	customAxios.interceptors.request.use(
		(request) => requestHandler(request),
		(error) => errorHandler(error),
	);
	
	customAxios.interceptors.response.use(
		(response) => responseHandler(response),
		(error) => errorHandler(error),
	 );

	 return customAxios;
}