<template>
	<v-app-bar
		app
		color="primary"
		dark
		fixed
		flat
		>
		<div>
			<v-img
			alt="Empire CBS"
			contain
			src="@/assets/empire_logo_dark.svg"
			transition="scale-transition"
			width="162"
			/>
			</div>
			<v-spacer></v-spacer>
			<template v-if="status.loggedIn">
				<v-app-bar-nav-icon  @click.stop="$emit('on-menu-click')"></v-app-bar-nav-icon>
			</template>
		</v-app-bar>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'AppBar',
	props: {
	},
	computed: {
		...mapState('user', ['status'],)
	},
	data: () => ({
	}),
}
</script>